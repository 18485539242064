.eventsMain-container {
  .events-main {
    padding: 0 20px;

    .all-events-manage {
      border: 1px solid lightgray;
      background-color: white;
      margin-bottom: 10px;

      .events-contents-area {
        display: flex;
        // justify-content: space-between;
        align-items: center;

        .datesandmonths {
          background-color: black;
          color: white;
          padding: 30px 10px;
          text-align: center;
          font-weight: 600;
          // width: 58px;
          line-height: 19px;

          .dates-events {
            font-weight: 700;
            font-size: 24px;
            margin-top: 5px;
          }
        }

        .events-parties-names {
          justify-content: space-between;
          align-items: center;
          display: flex;
          overflow: hidden;
          width: 100%;

          .events-and-parties {
            padding-left: 10px;
            flex: 1;

            .parties-even {
              text-transform: uppercase;
              cursor: pointer;
            }

            .parties-thems {
              font-size: 13px;
              margin-bottom: 0px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }

          .packagesandparking {
            display: flex;
            align-items: center;
            flex: 2;
            justify-content: end;

            .packages-eventsMain {
              border: 1px solid lightgray;
              padding: 6px 5px;
              margin-right: 21px;

              a.events.btn-btn {
                text-decoration: none;
                outline: 2px solid white;
                padding: 6px 12px 7px 12px;
              }
            }
          }
        }
      }
    }

    .draftClr {
      opacity: 0.4;
    }
  }
}

@media (min-width: 320px) and (max-width: 480px) {
  .eventsMain-container {
    width: 100%;
    margin-bottom: 12rem;
    .datesandmonths {
      padding: 25px 10px !important;
    }

    .events-parties-names {
      display: block !important;

      .events-and-parties {
        h5.parties-even {
          font-size: 15px;
        }
      }

      .packagesandparking {
        padding-top: 15px;
        padding-left: 9px;

        .packages-eventsMain {
          margin-right: 6px !important;
          padding: 6px !important;

          .eventButtons {
            padding: 0px 6px 5px 6px;
          }

          a.events.btn-btn {
            padding: 1px 5px 2px 4px !important;
          }
        }
      }
    }
  }
}

@media (min-width: 480px) and (max-width: 735px) {
  .eventsMain-container {
    .events-parties-names {
      display: block !important;

      h5.parties-even {
        font-size: 17px;
      }

      span.button-cnt {
        display: none;
      }

      .events-and-parties {
        padding-top: 4px !important;
      }

      .packages-eventsMain {
        margin-right: 8px !important;
        padding: 4px !important;

        a.events.btn-btn {
          padding: 0px 0px 5px 0px !important;
        }
      }

      .packagesandparking {
        padding-top: 14px;
        padding-left: 15px;
      }
    }
  }
}

@media (min-width: 735px) and (max-width: 979px) {
  .eventsMain-container {
    span.button-cnt {
      display: none;
    }
  }
}


.button1 {
  background: #dfdfdf;
  color: #ffffff;
}

.button2 {
  background: #4cb2dc;
  color: #ffffff;
}

.button3 {
  background: #ff2851;
  color: #ffffff;
}

.eventButtons {
  width: max-content;
  margin: auto;
  text-decoration: none;
  outline: 2px solid white;
  padding: 6px 12px 7px 12px;
  display: block;

  span.button-cnt {
    color: white;
    font-weight: 600;
    margin-left: 5px;
    cursor: pointer;
  }
}

@media (max-width: 768px) {
  .eventsMain-container .events-main .all-events-manage .events-contents-area {
    .datesandmonths {
      line-height: 24px;
    }
    .events-parties-names {
      .events-and-parties {
        width: 100%;
      }
      .packagesandparking {
        padding-top: 8px;
        padding-top: 4px;
      }
    }
  }
}
